<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5 pb-5">
      <bullet :title="'Accountancy'" />
      <v-row no-gutters class="mt-3">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" md="3" class="pa-1">
              <div>
                <p class="medium_txt bold ma-0 mr-2">Tanggal Awal</p>
                <v-menu
                  v-model="showTanggalAwal"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_awal"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tanggal awal di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_awal"
                    @input="closeDateMenu(field.tgl_awal, 'awal')"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
              <div>
                <p class="medium_txt bold ma-0">Tanggal Akhir</p>
                <v-menu
                  v-model="showTanggalAkhir"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_akhir"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tanggal akhir di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_akhir"
                    @input="closeDateMenu(field.tgl_akhir, 'akhir')"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12" md="2" class="pa-1 d-flex align-end">
              <v-btn
                :disabled="field.tgl_awal && field.tgl_akhir ? false : true"
                @click="searchData"
                depressed
                class="bg_color1 pa-2 text-capitalize"
              >
                <h4 class="txt_white">Cari Data</h4>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <div class="mt-3">
            <v-simple-table dense class="rounded" style="overflow: hidden">
              <template v-slot:default>
                <thead style="background: #052633">
                  <tr>
                    <th
                      class="text-left"
                      style="color: white; width: 40%; font-size: 15px"
                    >
                      Pendapatan
                    </th>
                    <th class="text-left" style="color: white; width: 20%"></th>
                    <th
                      class="text-right"
                      style="color: white; width: 20%"
                    ></th>
                    <th class="text-left" style="color: white; width: 20%"></th>
                  </tr>
                </thead>
                <tbody v-if="!loading && dataSet" style="background: white">
                  <tr v-for="(item, n) in dataSet.pendapatan" :key="n">
                    <td style="width: 40%; border: 0px">
                      <p class="ma-0">Pendapatan {{ item.projek }}</p>
                    </td>
                    <td style="width: 20%; border: 0px"></td>
                    <td class="text-right" style="width: 20%; border: 0px">
                      <p class="ma-0">
                        Rp. {{ formatRupiah(item.pendapatan) }}
                      </p>
                    </td>
                    <td style="width: 20%; border: 0px"></td>
                  </tr>
                </tbody>
                <tbody v-if="loading">
                  <tr
                    v-for="(item, y) in 2"
                    :key="y"
                    style="background: transparent !important"
                  >
                    <td
                      v-for="(item, n) in 4"
                      :key="n"
                      style="width: 40%; border: 0px"
                    >
                      <v-skeleton-loader
                        class="mt-2"
                        type="image"
                        height="30px"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div>
            <v-simple-table dense class="rounded" style="overflow: hidden">
              <template v-slot:default>
                <tbody v-if="dataSet && !loading" style="background: white">
                  <tr v-for="(item, n) in dataSet.pendapatan" :key="n">
                    <td style="width: 40%; border: 0px">
                      <p class="ma-0">Diskon {{ item.projek }}</p>
                    </td>
                    <td class="text-right" style="width: 20%; border: 0px">
                      <p class="ma-0">Rp. {{ formatRupiah(item.diskon) }}</p>
                    </td>
                    <td style="width: 20%; border: 0px"></td>
                    <td style="width: 20%; border: 0px"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table
              v-if="dataSet"
              dense
              class="rounded"
              style="overflow: hidden"
            >
              <template v-slot:default>
                <tbody
                  v-if="dataSet.pendapatan.length > 0"
                  style="background: white"
                >
                  <tr v-for="(item, n) in 1" :key="n">
                    <td class="text-left" style="width: 40%"></td>
                    <td class="text-left" style="width: 20%"></td>
                    <td class="text-right" style="width: 20%">
                      <p class="ma-0">
                        Rp. {{ formatRupiah(field.total_diskon) }}
                      </p>
                    </td>
                    <td class="text-left" style="width: 20%"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div v-if="!loading">
            <div
              style="color: #9e9e9e"
              class="mt-2 d-flex align-center justify-center"
            >
              <p v-if="!dataSet">No data available</p>
              <div v-if="dataSet">
                <p v-if="dataSet.pendapatan.length == 0">No data available</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div>
        <v-row no-gutters justify="end">
          <v-col cols="12" md="12">
            <div class="pa-2">
              <v-divider class="mb-3"></v-divider>
              <div class="d-flex justify-space-between">
                <h4>Pendapatan Bersih</h4>
                <h4>Rp. {{ formatRupiah(field.pendapatan_bersih) }}</h4>
              </div>
              <div class="mt-2 d-flex align-center justify-space-between">
                <p class="ma-0">Cost of Sales</p>
                <p class="ma-0">Rp. {{ formatRupiah(field.cost_of_sales) }}</p>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="mt-2 d-flex justify-space-between">
                <h4>Laba Kotor</h4>
                <h4>Rp. {{ formatRupiah(field.laba_kotor) }}</h4>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense class="rounded mt-3" style="overflow: hidden">
              <template v-slot:default>
                <thead style="background: #052633">
                  <tr>
                    <th
                      class="text-left"
                      style="color: white; width: 40%; font-size: 15px"
                    >
                      Beban Usaha
                    </th>
                    <th
                      class="text-right"
                      style="color: white; width: 20%"
                    ></th>
                    <th class="text-left" style="color: white; width: 20%"></th>
                    <th class="text-left" style="color: white; width: 20%"></th>
                  </tr>
                </thead>
                <tbody v-if="dataSet && !loading" style="background: white">
                  <tr v-for="(item, n) in dataSet.expenditure.beban" :key="n">
                    <td style="width: 40%; border: 0px">
                      <p class="ma-0 text-capitalize">
                        {{ item.beban.nama_beban }}
                      </p>
                    </td>
                    <td class="text-right" style="width: 20%; border: 0px">
                      <p class="ma-0">Rp. {{ formatRupiah(item.jumlah) }}</p>
                    </td>
                    <td style="width: 20%; border: 0px"></td>
                    <td style="width: 20%; border: 0px"></td>
                  </tr>
                </tbody>
                <tbody v-if="loading">
                  <tr
                    v-for="(item, y) in 2"
                    :key="y"
                    style="background: transparent !important"
                  >
                    <td
                      v-for="(item, n) in 4"
                      :key="n"
                      style="width: 40%; border: 0px"
                    >
                      <v-skeleton-loader
                        class="mt-2"
                        type="image"
                        height="30px"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table
              v-if="dataSet"
              dense
              class="rounded"
              style="overflow: hidden"
            >
              <template v-slot:default>
                <tbody
                  v-if="dataSet.expenditure.beban.length != 0"
                  style="background: white"
                >
                  <tr v-for="(item, n) in 1" :key="n">
                    <td class="text-left" style="width: 40%"></td>
                    <td class="text-left" style="width: 20%"></td>
                    <td class="text-left" style="width: 20%"></td>
                    <td class="text-right" style="width: 20%">
                      <p class="ma-0">
                        Rp. {{ formatRupiah(field.total_expenditure) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              v-if="!loading"
              style="color: #9e9e9e"
              class="mt-2 d-flex align-center justify-center"
            >
              <p v-if="!dataSet">No data available</p>
              <div v-if="dataSet">
                <p v-if="dataSet.expenditure.beban.length == 0">
                  No data available
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <div class="px-2">
              <v-divider class="my-3"></v-divider>
              <div class="mt-2 d-flex justify-space-between">
                <h4>Laba Bersih Sebelum Pajak</h4>
                <h4>Rp. {{ formatRupiah(field.laba_bersih_before_pajak) }}</h4>
              </div>
              <div class="mt-2 d-flex align-center justify-space-between">
                <div class="d-flex align-center" style="height: 100%">
                  <p class="ma-0">PPN</p>
                </div>
                <p class="ma-0">Rp. {{ formatRupiah(field.pajak_txt) }}</p>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="mt-2 d-flex justify-space-between">
                <h4>Laba Bersih</h4>
                <h4>Rp. {{ formatRupiah(field.laba_bersih) }}</h4>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="dataSet" no-gutters justify="end" class="mt-4">
          <v-col cols="12" md="2">
            <div class="pa-2 d-flex align-center justify-end">
              <v-btn
                block
                color="#052633"
                class="text-capitalize"
                @click="generateReport"
              >
                <!-- @click="createData" -->
                <h4 v-if="!loadingSave" class="txt_white">Generate PDF</h4>
                <h4 v-if="loadingSave" class="txt_white">Loading...</h4>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="dataSet && !loading">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
        :html-to-pdf-options="htmlToPdfOptions"
      >
        <!-- @beforeDownload="beforeDownload($event)" -->
        <section
          slot="pdf-content"
          style="
            width: 100%;
            display: flex;
            align-item: center;
            justify-content: center;
          "
        >
          <section
            class="pdf-item"
            style="width: 100%; padding-top: 8px; padding-bottom: 8px"
          >
            <pdf-accountancy
              :expenditure="dataSet.expenditure"
              :inputan="field"
              :pendapatan="dataSet.pendapatan"
            ></pdf-accountancy>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Bullet from "../../components/Etc/bullet.vue";
import navbarProfile from "../../components/navbarProfile/navbarProfile.vue";
import PdfAccountancy from "./pdfAccountancy.vue";
export default {
  components: { navbarProfile, Bullet, VueHtml2pdf, PdfAccountancy },
  name: "createAccountancy",
  data() {
    return {
      loading: false,
      loadingSave: false,
      field: {
        tgl_awal: "",
        tgl_akhir: "",
        cost_of_sales: 0,
        laba_kotor: 0,
        pendapatan_bersih: 0,
        total_expenditure: 0,
        total_diskon: 0,
        laba_bersih_before_pajak: 0,
        pajak: 11,
        pajak_txt: 0,
        laba_bersih: 0,
      },
      dataSet: null,
      showTanggalAwal: false,
      showTanggalAkhir: false,
      pendapatan: null,
      expenditure: null,
      htmlToPdfOptions: {
        margin: [10, 10, 10, 10],
        filename: `Laporan Akuntansi ${this.$date(new Date()).format(
          "DD_MM_YYYY_h_mm_A"
        )}.pdf`,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
      },
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Accountancy",
          disabled: false,
          href: "/admin/mku/accountancy",
        },
        {
          text: "Tambah Accountancy",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    // ubah format ke rupiah
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },

    // generate pdf
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    searchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/accountancy?tgl_awal=${this.field.tgl_awal}&tgl_akhir=${this.field.tgl_akhir}`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          console.log(data);
          this.dataSet = data;
          this.countLaba();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // hitung keuntungan
    async countLaba() {
      this.field.pendapatan_bersih = 0.0;
      this.field.total_expenditure = 0.0;
      this.field.cost_of_sales = 0.0;
      this.field.pajak_txt = 0.0;
      this.field.total_diskon = 0.0;
      this.field.laba_kotor = 0.0;
      this.field.laba_bersih = 0.0;
      this.dataSet.pendapatan.forEach((item) => {
        this.field.pendapatan_bersih =
          parseFloat(item.pendapatan) +
          this.field.pendapatan_bersih -
          parseFloat(item.diskon);
        this.field.pajak_txt = parseFloat(item.pajak) + this.field.pajak_txt;
        this.field.total_diskon =
          parseFloat(item.diskon) + parseFloat(this.field.total_diskon);
        console.log(item.diskon);
      });

      this.dataSet.expenditure.beban.forEach((item) => {
        item.jumlah = item.qty * item.cost;
        this.field.total_expenditure =
          parseFloat(item.jumlah) + this.field.total_expenditure;
      });
      this.dataSet.expenditure.cost_of_sales.forEach((item) => {
        console.log(item);
        this.field.cost_of_sales =
          item.qty * item.cost + this.field.cost_of_sales;
      });
      this.field.laba_kotor =
        this.field.pendapatan_bersih - this.field.cost_of_sales;
      this.field.laba_bersih_before_pajak =
        this.field.laba_kotor - this.field.total_expenditure;
      // this.field.pajak_txt =
      //   (this.field.laba_bersih_before_pajak * this.field.pajak) / 100;
      this.field.laba_bersih =
        this.field.laba_bersih_before_pajak - this.field.pajak_txt;
      this.loading = false;
    },
    closeDateMenu(item, type) {
      if (type == "awal") {
        this.showTanggalAwal = false;
        this.field.tgl_awal == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.showTanggalAkhir = false;
        this.field.tgl_akhir == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    createData() {
      this.loadingSave = true;
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
</style>
