<template>
  <div v-if="pendapatan && expenditure && inputan">
    <div style="">
      <div
        style="
          display: flex;
          align-item: center;
          justify-content: center;
          padding: 0px 10px;
        "
      >
        <div style="width: 80%">
          <p style="text-align: center; font-size: 18px; font-weight: bold">
            Laporan Laba/Rugi Biro Psikologi PT Hara Anargya
          </p>
          <p style="text-align: center; font-size: 15px">
            Jalan PH.H. Mustofa, Surapati Core Blok C 22, Pasirlayung,
          </p>

          <p style="text-align: center; font-size: 15px">
            Kec. Cibeunying Kidul, Kota Bandung, Jawa Barat
          </p>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px; padding: 0px 10px">
      <div
        style="
          margin-top: 12px;
          width: 100%;
          display: flex;
          align-item: center;
          justify-content: center;
          text-align: center;
        "
      >
        <div style="padding: 10px">
          <p class="small_txt" style="margin: 0px">Tanggal Transaksi</p>
          <h4>
            {{
              $date(new Date(inputan.tgl_awal)).format("DD MMMM YYYY") +
              " - " +
              $date(new Date(inputan.tgl_akhir)).format("DD MMMM YYYY")
            }}
          </h4>
        </div>
      </div>
      <table style="width: 100%; border-collapse: collapse; margin-top: 12px">
        <tr style="background: #052633; border-radius: 8px 8px 0px 0px">
          <th
            style="
              color: white;
              width: 40%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          >
            Pendapatan
          </th>
          <th
            style="
              color: white;
              width: 20%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          ></th>
          <th
            style="
              color: white;
              width: 20%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          ></th>
          <th
            style="
              color: white;
              width: 20%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          ></th>
        </tr>
        <tr v-for="(item, n) in pendapatan" :key="n">
          <td
            style="
              width: 40%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          >
            <p style="margin: 0px; text-transform: capitalize">
              Pendapatan {{ item.projek }}
            </p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0px">Rp. {{ formatRupiah(item.pendapatan) }}</p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
        </tr>
        <tr v-for="(item, n) in pendapatan" :key="`${n}+ diskon`">
          <td
            style="
              width: 40%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          >
            <p style="margin: 0px; text-transform: capitalize">
              Diskon {{ item.projek }}
            </p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0px">Rp. {{ formatRupiah(item.diskon) }}</p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>

          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
        </tr>
        <tr>
          <td
            style="
              width: 40%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>

          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0px">
              Rp. {{ formatRupiah(inputan.total_diskon) }}
            </p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
        </tr>
      </table>
      <hr style="margin: 12px 0px; border-top: 1px solid #cdd0d1" />
      <table style="width: 100%; border-collapse: collapse">
        <tr>
          <td style="width: 50%; padding: 5px 10px 5px 10px; text-align: left">
            <h4>Pendapatan Bersih</h4>
          </td>
          <td
            style="
              width: 50%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <h4>Rp. {{ formatRupiah(inputan.pendapatan_bersih) }}</h4>
          </td>
        </tr>
        <tr>
          <td style="width: 50%; padding: 5px 10px 5px 10px; text-align: left">
            <p style="margin: 0">Cost of Sales</p>
          </td>
          <td
            style="
              width: 50%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0">
              Rp. {{ formatRupiah(inputan.cost_of_sales) }}
            </p>
          </td>
        </tr>
      </table>
      <hr style="margin: 12px 0px; border-top: 1px solid #cdd0d1" />
      <table style="width: 100%; border-collapse: collapse">
        <tr>
          <td style="width: 50%; padding: 5px 10px 5px 10px; text-align: left">
            <h4>Laba Kotor</h4>
          </td>
          <td
            style="
              width: 50%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <h4>Rp. {{ formatRupiah(inputan.laba_kotor) }}</h4>
          </td>
        </tr>
      </table>
      <table style="width: 100%; border-collapse: collapse; margin-top: 12px">
        <tr style="background: #052633; border-radius: 8px 8px 0px 0px">
          <th
            style="
              color: white;
              width: 40%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          >
            Beban Usaha
          </th>
          <th
            style="
              color: white;
              width: 20%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          ></th>
          <th
            style="
              color: white;
              width: 20%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          ></th>
          <th
            style="
              color: white;
              width: 20%;
              font-size: 15px;
              padding: 10px 10px 10px 10px;
              text-align: left;
            "
          ></th>
        </tr>
        <tr v-for="(item, n) in expenditure.beban" :key="n">
          <td
            style="
              width: 40%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          >
            <p style="margin: 0; text-transform: capitalize">
              {{ item.beban.nama_beban }}
            </p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0">Rp. {{ formatRupiah(item.jumlah) }}</p>
          </td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>

          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
        </tr>
        <tr>
          <td
            style="
              width: 40%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: left;
            "
          ></td>
          <td
            style="
              width: 20%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0px">
              Rp. {{ formatRupiah(inputan.total_expenditure) }}
            </p>
          </td>
        </tr>
      </table>
      <hr style="margin: 12px 0px; border-top: 1px solid #cdd0d1" />
      <table style="width: 100%; border-collapse: collapse">
        <tr>
          <td style="width: 50%; padding: 5px 10px 5px 10px; text-align: left">
            <h4>Laba Bersih Sebelum Pajak</h4>
          </td>
          <td
            style="
              width: 50%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <h4>Rp. {{ formatRupiah(inputan.laba_bersih_before_pajak) }}</h4>
          </td>
        </tr>
        <tr>
          <td style="width: 50%; padding: 5px 10px 5px 10px; text-align: left">
            <p style="margin: 0">PPN</p>
          </td>
          <td
            style="
              width: 50%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <p style="margin: 0">Rp. {{ formatRupiah(inputan.pajak_txt) }}</p>
          </td>
        </tr>
      </table>
      <hr style="margin: 12px 0px; border-top: 1px solid #cdd0d1" />
      <table style="width: 100%; border-collapse: collapse">
        <tr>
          <td style="width: 50%; padding: 5px 10px 5px 10px; text-align: left">
            <h4>Laba Bersih</h4>
          </td>
          <td
            style="
              width: 50%;
              font-size: 15px;
              padding: 5px 10px 5px 10px;
              text-align: right;
            "
          >
            <h4>Rp. {{ formatRupiah(inputan.laba_bersih) }}</h4>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "pdfAccountancy",
  props: ["pendapatan", "expenditure", "inputan"],

  methods: {
    // ubah format ke rupiah
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
  },
};
</script>

<style></style>
